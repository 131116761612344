@import 'npm:@fontsource-variable/inter/wght.css';
@import 'npm:@fontsource-variable/inter-tight/wght.css';
@import 'npm:@fontsource-variable/roboto-mono/wght.css';
@import 'npm:@fortawesome/fontawesome-free/css/all.css';

@import './smiles.css';

body.theme-light, body.theme-system {
  color-scheme: light;
  --main-hue: 210; // bluish
  --danger-hue: 0; // red
  --body-bg-color: hsl(var(--main-hue), 10%, 60%);
  --main-color: hsl(var(--main-hue), 10%, 10%);
  --danger-color: hsl(var(--danger-hue), 60%, 40%);
  --selection-color: hsl(var(--main-hue), 10%, 90%);
  --selection-bg-color: hsl(var(--main-hue), 90%, 60%);
  --selection-danger-color: hsl(var(--danger-hue), 90%, 10%);
  --selection-danger-bg-color: hsl(var(--danger-hue), 90%, 40%);
  --sheet-bg-color: hsl(var(--main-hue), 10%, 80%);
  --panel-bg-color: hsl(var(--main-hue), 10%, 70%);
  --area-bg-color: hsl(var(--main-hue), 10%, 90%);
  --border-color: hsl(var(--main-hue), 10%, 20%, 0.3);
  --sheet-shadow: 0 4px 20px 0px #0003;
  --main-weak-color: hsl(var(--main-hue), 10%, 40%);
  --link-color: hsl(var(--main-hue), 100%, 30%);
  --link-hover-color: hsl(var(--main-hue), 100%, 50%);
  --input-bg-color: hsl(var(--main-hue), 50%, 95%);
  --input-shadow: 0 0 4px -2px hsl(var(--main-hue), 10%, 50%);
  --input-glow: 0 0 6px -2px hsl(var(--main-hue), 80%, 50%);
  --input-glow-danger: 0 0 6px -2px hsl(var(--danger-hue), 80%, 50%);
  --button-bg-color: hsl(var(--main-hue), 50%, 90%, 0.4);
  --list-hover-bg-color: hsl(var(--main-hue), 90%, 60%, 0.3);
  --progress-normal-color: hsl(var(--main-hue), 50%, 50%);
  --progress-normal-bg-color: hsl(var(--main-hue), 50%, 50%, 0.4);
  --progress-ok-color: #090;
  --progress-ok-bg-color: #00990080;
  --progress-error-color: #f00;
  --progress-error-bg-color: #ff000040;
  --error-color: hsl(var(--danger-hue), 90%, 10%);
  --button-active-bg-color: hsl(var(--main-hue), 80%, 90%, 0.2);
  --button-active-shadow-color: hsl(var(--main-hue), 80%, 50%);
  --button-active-danger-bg-color: hsl(var(--danger-hue), 80%, 90%, 0.2);
  --button-active-danger-shadow-color: hsl(var(--danger-hue), 80%, 50%);
}
@mixin theme-dark {
  color-scheme: dark;
  --main-hue: 200; // bluish
  --danger-hue: 0; // red
  --body-bg-color: hsl(var(--main-hue), 10%, 10%);
  --main-color: hsl(var(--main-hue), 10%, 80%);
  --danger-color: hsl(var(--danger-hue), 60%, 60%);
  --selection-color: hsl(var(--main-hue), 10%, 90%);
  --selection-bg-color: hsl(var(--main-hue), 90%, 30%);
  --selection-danger-color: hsl(var(--danger-hue), 90%, 90%);
  --selection-danger-bg-color: hsl(var(--danger-hue), 90%, 40%);
  --sheet-bg-color: hsl(var(--main-hue), 10%, 20%);
  --panel-bg-color: hsl(var(--main-hue), 10%, 25%);
  --area-bg-color: hsl(var(--main-hue), 10%, 15%);
  --border-color: hsl(var(--main-hue), 50%, 40%, 0.3);
  --sheet-shadow: 0 4px 20px 0px hsl(var(--main-hue), 100%, 50%, 0.1);
  --main-weak-color: hsl(var(--main-hue), 10%, 60%);
  --link-color: hsl(var(--main-hue), 100%, 70%);
  --link-hover-color: hsl(var(--main-hue), 100%, 80%);
  --input-bg-color: hsl(var(--main-hue), 20%, 10%);
  --input-shadow: 0 0 4px -2px hsl(var(--main-hue), 10%, 50%);
  --input-glow: 0 0 6px -2px hsl(var(--main-hue), 80%, 50%);
  --input-glow-danger: 0 0 6px -2px hsl(var(--danger-hue), 80%, 50%);
  --button-bg-color: hsl(var(--main-hue), 50%, 20%);
  --list-hover-bg-color: hsl(var(--main-hue), 90%, 60%, 0.3);
  --progress-normal-color: hsl(var(--main-hue), 50%, 50%);
  --progress-normal-bg-color: hsl(var(--main-hue), 50%, 50%, 0.4);
  --progress-ok-color: #090;
  --progress-ok-bg-color: #00990080;
  --progress-error-color: #f00;
  --progress-error-bg-color: #ff000040;
  --error-color: hsl(var(--danger-hue), 90%, 10%);
  --button-active-bg-color: hsl(var(--main-hue), 50%, 30%);
  --button-active-shadow-color: hsl(var(--main-hue), 80%, 50%);
  --button-active-danger-bg-color: hsl(var(--danger-hue), 50%, 30%);
  --button-active-danger-shadow-color: hsl(var(--danger-hue), 80%, 50%);
}
body.theme-dark {
  @include theme-dark;
}
@media (prefers-color-scheme: dark) {
  body.theme-system {
    @include theme-dark;
  }
}

body {
  margin: 0;
  padding: 0;
  background: var(--body-bg-color);
  overflow: clip;
}

h3, p, div, span, button, input {
  font-family: 'Inter Variable', system-ui;
  color: var(--main-color);
}
h1, h2, .app_name {
  font-family: 'Inter Tight Variable', system-ui;
  color: var(--main-color);
}
code, pre {
  font-family: 'Roboto Mono Variable', monospace;
  color: var(--main-color);
  font-size: 14px;
  font-weight: 450;
}
p, div, button, input {
  font-size: 14px;
  font-weight: 500;
}

.stream {
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-columns: 1fr min-content;
  place-content: stretch;
  place-items: stretch;
  & > iframe {
    grid-column: 1;
    background: var(--area-bg-color);
    border: none;
    overflow: hidden;
    min-height: 70vh;
  }
  & > .chat {
    grid-column: 2;
    min-width: 400px;
  }
}

.popout {
  position: absolute;
  inset: 0;
  display: grid;
  grid-template-columns: 1fr;
  place-content: stretch;
  place-items: stretch;
}

.chat {
  max-height: 100vh;
  contain: content;
  display: grid;
  grid-auto-flow: column;
  grid-template-rows: 1fr min-content min-content;
  place-content: stretch;
  place-items: stretch;
  & > .messages {
    display: grid;
    grid-template-columns: min-content 1fr;
    place-content: start stretch;
    place-items: baseline start;
    gap: 4px;
    overflow: hidden scroll;
    & > * { // message
      grid-column: 1 / -1;
      display: grid;
      grid-template-columns: subgrid;
      place-content: start stretch;
      place-items: baseline start;
      border-block-start: 0.5px solid var(--border-color);
      margin-block: 5px;
      & > .author {
        grid-column: 1;
        color: var(--main-weak-color);
      }
      & > .content {
        grid-column: 2;
      }
    }
  }
  & > .smiles {
    border: 0.5px solid var(--border-color);
    max-height: 200px;
    overflow: hidden auto;
    display: flex;
    flex-wrap: wrap;
    gap: 4px;
  }
  & > input {
  }
  & > a {
    place-self: center;
  }
}

.smile {
  display: inline-block;
  position: relative;
  inset-block-start: 5px;
  margin-block-start: -5px;
  background-repeat: no-repeat;
}

input[type='text'], input[type='password'], input[type="number"] {
  font-size: 14px;
  background: var(--input-bg-color);
  border: 0.5px solid var(--border-color);
  border-radius: 4px;
  box-shadow: var(--input-shadow);
  outline: none;
  padding: 4px;
  &:focus-visible {
    box-shadow: var(--input-glow);
  }
  &:invalid {
    border-color: var(--danger-color);
    &:focus-visible {
      box-shadow: var(--input-glow-danger);
    }
  }
}
