.smile_777 { width: 30px; height: 30px; background: url(./smiles/777.png) }
.smile_aba { width: 32px; height: 30px; background: url(./smiles/aba.png) }
.smile_abver { width: 30px; height: 30px; background: url(./smiles/abver.png) }
.smile_acab { width: 30px; height: 30px; background: url(./smiles/acab.png) }
.smile_adolf { width: 27px; height: 33px; background: url(./smiles/adolf.png) }
.smile_adolfuck { width: 30px; height: 30px; background: url(./smiles/adolfuck.png) }
.smile_adybahface { width: 30px; height: 30px; background: url(./smiles/adybahface.png) }
.smile_afflek { width: 30px; height: 30px; background: url(./smiles/afflek.png) }
.smile_agne { width: 30px; height: 30px; background: url(./smiles/agne.png) }
.smile_ahegao { width: 30px; height: 30px; background: url(./smiles/ahegao.png) }
.smile_akbar { width: 30px; height: 30px; background: url(./smiles/akbar.png) }
.smile_aku { width: 30px; height: 30px; background: url(./smiles/aku.png) }
.smile_albert { width: 30px; height: 30px; background: url(./smiles/albert.png) }
.smile_allopool { width: 30px; height: 30px; background: url(./smiles/allopool.png) }
.smile_aloha { width: 30px; height: 30px; background: url(./smiles/aloha.png) }
.smile_alone { width: 31px; height: 30px; background: url(./smiles/alone.png) }
.smile_am { width: 61px; height: 30px; background: url(./smiles/am.png) }
.smile_ananism { width: 30px; height: 30px; background: url(./smiles/ananism.png) }
.smile_angry { width: 30px; height: 30px; background: url(./smiles/angry.png) }
.smile_angryling { width: 30px; height: 30px; background: url(./smiles/angryling.png) }
.smile_aniadolf { width: 36px; height: 30px; background: url(./smiles/aniadolf.png) }
.smile_aniblitz { width: 30px; height: 30px; background: url(./smiles/aniblitz.png) }
.smile_anibutth { width: 45px; height: 30px; background: url(./smiles/anibutth.png) }
.smile_anidance { width: 54px; height: 30px; background: url(./smiles/anidance.png) }
.smile_anifeelsgood { width: 30px; height: 30px; background: url(./smiles/anifeelsgood.png) }
.smile_anifive { width: 45px; height: 30px; background: url(./smiles/anifive.png) }
.smile_anifp { width: 30px; height: 30px; background: url(./smiles/anifp.png) }
.smile_anifrog { width: 37px; height: 30px; background: url(./smiles/anifrog.png) }
.smile_anifrog2 { width: 30px; height: 30px; background: url(./smiles/anifrog2.png) }
.smile_anifrog3 { width: 36px; height: 30px; background: url(./smiles/anifrog3.png) }
.smile_anifroghanged { width: 30px; height: 30px; background: url(./smiles/anifroghanged.png) }
.smile_anigandalf { width: 73px; height: 30px; background: url(./smiles/anigandalf.png) }
.smile_anigglord { width: 70px; height: 30px; background: url(./smiles/anigglord.png) }
.smile_anighost { width: 45px; height: 30px; background: url(./smiles/anighost.png) }
.smile_animofgod { width: 30px; height: 30px; background: url(./smiles/animofgod.png) }
.smile_aninichosi { width: 30px; height: 30px; background: url(./smiles/aninichosi.png) }
.smile_aniopeka { width: 30px; height: 30px; background: url(./smiles/aniopeka.png) }
.smile_anipryatki { width: 31px; height: 30px; background: url(./smiles/anipryatki.png) }
.smile_anisnoop { width: 30px; height: 30px; background: url(./smiles/anisnoop.png) }
.smile_aniupeka { width: 30px; height: 30px; background: url(./smiles/aniupeka.png) }
.smile_anon { width: 30px; height: 30px; background: url(./smiles/anon.png) }
.smile_anonim { width: 30px; height: 30px; background: url(./smiles/anonim.png) }
.smile_antipeka { width: 30px; height: 30px; background: url(./smiles/antipeka.png) }
.smile_anzu { width: 30px; height: 30px; background: url(./smiles/anzu.png) }
.smile_apc { width: 26px; height: 30px; background: url(./smiles/apc.png) }
.smile_appl { width: 30px; height: 30px; background: url(./smiles/appl.png) }
.smile_armedpeka { width: 30px; height: 30px; background: url(./smiles/armedpeka.png) }
.smile_arni { width: 20px; height: 30px; background: url(./smiles/arni.png) }
.smile_ashfp { width: 30px; height: 30px; background: url(./smiles/ashfp.png) }
.smile_ashgo { width: 30px; height: 30px; background: url(./smiles/ashgo.png) }
.smile_ashlol { width: 30px; height: 30px; background: url(./smiles/ashlol.png) }
.smile_ashpeace { width: 30px; height: 30px; background: url(./smiles/ashpeace.png) }
.smile_ashrage { width: 30px; height: 30px; background: url(./smiles/ashrage.png) }
.smile_asuka { width: 25px; height: 30px; background: url(./smiles/asuka.png) }
.smile_aws { width: 30px; height: 30px; background: url(./smiles/aws.png) }
.smile_bach { width: 45px; height: 30px; background: url(./smiles/bach.png) }
.smile_badfox2 { width: 30px; height: 30px; background: url(./smiles/badfox2.png) }
.smile_bahpeka { width: 30px; height: 30px; background: url(./smiles/bahpeka.png) }
.smile_baiken { width: 30px; height: 30px; background: url(./smiles/baiken.png) }
.smile_bailey { width: 30px; height: 30px; background: url(./smiles/bailey.png) }
.smile_ban { width: 21px; height: 30px; background: url(./smiles/ban.png) }
.smile_battletoads { width: 30px; height: 30px; background: url(./smiles/battletoads.png) }
.smile_batyut { width: 45px; height: 30px; background: url(./smiles/batyut.png) }
.smile_bbpeka { width: 30px; height: 30px; background: url(./smiles/bbpeka.png) }
.smile_bbskull { width: 30px; height: 30px; background: url(./smiles/bbskull.png) }
.smile_bear { width: 32px; height: 30px; background: url(./smiles/bear.png) }
.smile_beda { width: 28px; height: 30px; background: url(./smiles/beda.png) }
.smile_bedpeka { width: 30px; height: 30px; background: url(./smiles/bedpeka.png) }
.smile_beka { width: 30px; height: 30px; background: url(./smiles/beka.png) }
.smile_bender { width: 35px; height: 30px; background: url(./smiles/bender.png) }
.smile_bgn { width: 45px; height: 30px; background: url(./smiles/bgn.png) }
.smile_bin { width: 30px; height: 30px; background: url(./smiles/bin.png) }
.smile_bird { width: 30px; height: 30px; background: url(./smiles/bird.png) }
.smile_bisu { width: 26px; height: 33px; background: url(./smiles/bisu.png) }
.smile_bkljah { width: 30px; height: 30px; background: url(./smiles/bkljah.png) }
.smile_blast { width: 30px; height: 30px; background: url(./smiles/blast.png) }
.smile_blinky { width: 30px; height: 30px; background: url(./smiles/blinky.png) }
.smile_bloodhand { width: 30px; height: 30px; background: url(./smiles/bloodhand.png) }
.smile_bloodlove { width: 30px; height: 30px; background: url(./smiles/bloodlove.png) }
.smile_blue { width: 32px; height: 30px; background: url(./smiles/blue.png) }
.smile_bm { width: 52px; height: 30px; background: url(./smiles/bm.png) }
.smile_bo4ka { width: 30px; height: 30px; background: url(./smiles/bo4ka.png) }
.smile_bob { width: 30px; height: 30px; background: url(./smiles/bob.png) }
.smile_bobr { width: 30px; height: 30px; background: url(./smiles/bobr.png) }
.smile_bolt { width: 30px; height: 30px; background: url(./smiles/bolt.png) }
.smile_bomber { width: 30px; height: 30px; background: url(./smiles/bomber.png) }
.smile_boom { width: 30px; height: 30px; background: url(./smiles/boom.png) }
.smile_boomsnow { width: 30px; height: 30px; background: url(./smiles/boomsnow.png) }
.smile_boyan { width: 30px; height: 30px; background: url(./smiles/boyan.png) }
.smile_bpalm { width: 30px; height: 30px; background: url(./smiles/bpalm.png) }
.smile_br { width: 27px; height: 30px; background: url(./smiles/br.png) }
.smile_brain { width: 30px; height: 30px; background: url(./smiles/brain.png) }
.smile_brat { width: 30px; height: 30px; background: url(./smiles/brat.png) }
.smile_bratok { width: 27px; height: 33px; background: url(./smiles/bratok.png) }
.smile_brofist { width: 30px; height: 30px; background: url(./smiles/brofist.png) }
.smile_brung { width: 30px; height: 30px; background: url(./smiles/brung.png) }
.smile_bsad { width: 30px; height: 30px; background: url(./smiles/bsad.png) }
.smile_buee { width: 30px; height: 30px; background: url(./smiles/buee.png) }
.smile_buepeka { width: 30px; height: 30px; background: url(./smiles/buepeka.png) }
.smile_buernbw { width: 26px; height: 30px; background: url(./smiles/buernbw.png) }
.smile_bunny { width: 30px; height: 30px; background: url(./smiles/bunny.png) }
.smile_butth { width: 30px; height: 30px; background: url(./smiles/butth.png) }
.smile_butthurt { width: 30px; height: 30px; background: url(./smiles/butthurt.png) }
.smile_bykov { width: 30px; height: 30px; background: url(./smiles/bykov.png) }
.smile_cage { width: 30px; height: 30px; background: url(./smiles/cage.png) }
.smile_cannon { width: 30px; height: 30px; background: url(./smiles/cannon.png) }
.smile_cap { width: 35px; height: 25px; background: url(./smiles/cap.png) }
.smile_capitan { width: 30px; height: 30px; background: url(./smiles/capitan.png) }
.smile_cartman { width: 30px; height: 30px; background: url(./smiles/cartman.png) }
.smile_casterling { width: 31px; height: 30px; background: url(./smiles/casterling.png) }
.smile_castle { width: 30px; height: 30px; background: url(./smiles/castle.png) }
.smile_cat { width: 30px; height: 30px; background: url(./smiles/cat.png) }
.smile_cat2 { width: 30px; height: 30px; background: url(./smiles/cat2.png) }
.smile_catbox { width: 30px; height: 30px; background: url(./smiles/catbox.png) }
.smile_catdance { width: 30px; height: 30px; background: url(./smiles/catdance.png) }
.smile_catok { width: 43px; height: 30px; background: url(./smiles/catok.png) }
.smile_cbgits { width: 30px; height: 30px; background: url(./smiles/cbgits.png) }
.smile_chajnik { width: 30px; height: 30px; background: url(./smiles/chajnik.png) }
.smile_che { width: 35px; height: 30px; background: url(./smiles/che.png) }
.smile_cheese { width: 30px; height: 30px; background: url(./smiles/cheese.png) }
.smile_chester { width: 30px; height: 30px; background: url(./smiles/chester.png) }
.smile_chimichanga { width: 30px; height: 30px; background: url(./smiles/chimichanga.png) }
.smile_choko { width: 45px; height: 30px; background: url(./smiles/choko.png) }
.smile_chuck { width: 30px; height: 30px; background: url(./smiles/chuck.png) }
.smile_clint { width: 30px; height: 30px; background: url(./smiles/clint.png) }
.smile_cobra { width: 30px; height: 30px; background: url(./smiles/cobra.png) }
.smile_cock { width: 30px; height: 30px; background: url(./smiles/cock.png) }
.smile_coin { width: 30px; height: 30px; background: url(./smiles/coin.png) }
.smile_comfrog { width: 30px; height: 30px; background: url(./smiles/comfrog.png) }
.smile_conor { width: 30px; height: 30px; background: url(./smiles/conor.png) }
.smile_contratyan { width: 30px; height: 30px; background: url(./smiles/contratyan.png) }
.smile_coolio { width: 30px; height: 30px; background: url(./smiles/coolio.png) }
.smile_coolpeka { width: 30px; height: 30px; background: url(./smiles/coolpeka.png) }
.smile_corona { width: 30px; height: 30px; background: url(./smiles/corona.png) }
.smile_cotoface { width: 30px; height: 30px; background: url(./smiles/cotoface.png) }
.smile_cotopec { width: 30px; height: 30px; background: url(./smiles/cotopec.png) }
.smile_cou { width: 30px; height: 30px; background: url(./smiles/cou.png) }
.smile_coupeka { width: 30px; height: 30px; background: url(./smiles/coupeka.png) }
.smile_cow { width: 30px; height: 23px; background: url(./smiles/cow.png) }
.smile_cowboy { width: 33px; height: 30px; background: url(./smiles/cowboy.png) }
.smile_crab { width: 34px; height: 30px; background: url(./smiles/crab.png) }
.smile_crash { width: 30px; height: 30px; background: url(./smiles/crash.png) }
.smile_crazy { width: 30px; height: 30px; background: url(./smiles/crazy.png) }
.smile_crazyface { width: 30px; height: 30px; background: url(./smiles/crazyface.png) }
.smile_creeper { width: 30px; height: 30px; background: url(./smiles/creeper.png) }
.smile_croko { width: 30px; height: 30px; background: url(./smiles/croko.png) }
.smile_cry { width: 30px; height: 30px; background: url(./smiles/cry.png) }
.smile_cthulhu { width: 30px; height: 30px; background: url(./smiles/cthulhu.png) }
.smile_cultist1 { width: 30px; height: 30px; background: url(./smiles/cultist1.png) }
.smile_cute { width: 30px; height: 30px; background: url(./smiles/cute.png) }
.smile_d4l { width: 30px; height: 30px; background: url(./smiles/d4l.png) }
.smile_daaa { width: 33px; height: 30px; background: url(./smiles/daaa.png) }
.smile_daihsnim { width: 30px; height: 30px; background: url(./smiles/daihsnim.png) }
.smile_danu { width: 30px; height: 30px; background: url(./smiles/danu.png) }
.smile_dark { width: 30px; height: 30px; background: url(./smiles/dark.png) }
.smile_daun { width: 30px; height: 30px; background: url(./smiles/daun.png) }
.smile_daya { width: 30px; height: 30px; background: url(./smiles/daya.png) }
.smile_db { width: 23px; height: 30px; background: url(./smiles/db.png) }
.smile_deadpool { width: 45px; height: 30px; background: url(./smiles/deadpool.png) }
.smile_deadpoop { width: 30px; height: 30px; background: url(./smiles/deadpoop.png) }
.smile_deal { width: 30px; height: 30px; background: url(./smiles/deal.png) }
.smile_dedushka { width: 30px; height: 30px; background: url(./smiles/dedushka.png) }
.smile_demon { width: 30px; height: 30px; background: url(./smiles/demon.png) }
.smile_dendy { width: 30px; height: 30px; background: url(./smiles/dendy.png) }
.smile_denv { width: 30px; height: 30px; background: url(./smiles/denv.png) }
.smile_denverpeka { width: 30px; height: 30px; background: url(./smiles/denverpeka.png) }
.smile_derp { width: 28px; height: 31px; background: url(./smiles/derp.png) }
.smile_devka { width: 30px; height: 30px; background: url(./smiles/devka.png) }
.smile_diana { width: 30px; height: 30px; background: url(./smiles/diana.png) }
.smile_dice1 { width: 30px; height: 30px; background: url(./smiles/dice1.png) }
.smile_dice6 { width: 30px; height: 30px; background: url(./smiles/dice6.png) }
.smile_dich { width: 30px; height: 30px; background: url(./smiles/dich.png) }
.smile_dich1 { width: 30px; height: 30px; background: url(./smiles/dich1.png) }
.smile_dimaga { width: 27px; height: 33px; background: url(./smiles/dimaga.png) }
.smile_dispeka { width: 42px; height: 30px; background: url(./smiles/dispeka.png) }
.smile_dj { width: 30px; height: 30px; background: url(./smiles/dj.png) }
.smile_dniwe { width: 30px; height: 30px; background: url(./smiles/dniwe.png) }
.smile_dobro { width: 30px; height: 30px; background: url(./smiles/dobro.png) }
.smile_dogopek { width: 30px; height: 30px; background: url(./smiles/dogopek.png) }
.smile_donat { width: 45px; height: 30px; background: url(./smiles/donat.png) }
.smile_drama { width: 30px; height: 30px; background: url(./smiles/drama.png) }
.smile_drav { width: 31px; height: 30px; background: url(./smiles/drav.png) }
.smile_dream { width: 30px; height: 30px; background: url(./smiles/dream.png) }
.smile_drill { width: 30px; height: 30px; background: url(./smiles/drill.png) }
.smile_drone { width: 30px; height: 30px; background: url(./smiles/drone.png) }
.smile_drow { width: 30px; height: 30px; background: url(./smiles/drow.png) }
.smile_druzhko { width: 30px; height: 30px; background: url(./smiles/druzhko.png) }
.smile_ducalis { width: 25px; height: 30px; background: url(./smiles/ducalis.png) }
.smile_eastwood { width: 30px; height: 30px; background: url(./smiles/eastwood.png) }
.smile_eboy { width: 60px; height: 30px; background: url(./smiles/eboy.png) }
.smile_ecat { width: 45px; height: 30px; background: url(./smiles/ecat.png) }
.smile_echicken { width: 30px; height: 30px; background: url(./smiles/echicken.png) }
.smile_egrumpy { width: 30px; height: 30px; background: url(./smiles/egrumpy.png) }
.smile_ehmm { width: 45px; height: 30px; background: url(./smiles/ehmm.png) }
.smile_ej { width: 30px; height: 30px; background: url(./smiles/ej.png) }
.smile_elite { width: 30px; height: 30px; background: url(./smiles/elite.png) }
.smile_elka { width: 30px; height: 30px; background: url(./smiles/elka.png) }
.smile_emche { width: 30px; height: 30px; background: url(./smiles/emche.png) }
.smile_emcorn { width: 30px; height: 30px; background: url(./smiles/emcorn.png) }
.smile_emlove { width: 30px; height: 30px; background: url(./smiles/emlove.png) }
.smile_emotegoose { width: 30px; height: 30px; background: url(./smiles/emotegoose.png) }
.smile_empr { width: 30px; height: 29px; background: url(./smiles/empr.png) }
.smile_empriv { width: 30px; height: 30px; background: url(./smiles/empriv.png) }
.smile_emuooo { width: 30px; height: 30px; background: url(./smiles/emuooo.png) }
.smile_epeka { width: 30px; height: 30px; background: url(./smiles/epeka.png) }
.smile_eron { width: 45px; height: 30px; background: url(./smiles/eron.png) }
.smile_evans { width: 30px; height: 30px; background: url(./smiles/evans.png) }
.smile_evil { width: 30px; height: 30px; background: url(./smiles/evil.png) }
.smile_evrei { width: 28px; height: 30px; background: url(./smiles/evrei.png) }
.smile_ewww { width: 30px; height: 30px; background: url(./smiles/ewww.png) }
.smile_explosion { width: 30px; height: 30px; background: url(./smiles/explosion.png) }
.smile_eyeroll { width: 30px; height: 30px; background: url(./smiles/eyeroll.png) }
.smile_fallout { width: 30px; height: 30px; background: url(./smiles/fallout.png) }
.smile_fancy { width: 30px; height: 30px; background: url(./smiles/fancy.png) }
.smile_fara { width: 30px; height: 30px; background: url(./smiles/fara.png) }
.smile_faust { width: 30px; height: 30px; background: url(./smiles/faust.png) }
.smile_fboy { width: 30px; height: 30px; background: url(./smiles/fboy.png) }
.smile_feelsgood { width: 30px; height: 30px; background: url(./smiles/feelsgood.png) }
.smile_ff { width: 30px; height: 30px; background: url(./smiles/ff.png) }
.smile_fgfrog { width: 30px; height: 30px; background: url(./smiles/fgfrog.png) }
.smile_fibo { width: 45px; height: 30px; background: url(./smiles/fibo.png) }
.smile_file { width: 30px; height: 30px; background: url(./smiles/file.png) }
.smile_fire { width: 30px; height: 30px; background: url(./smiles/fire.png) }
.smile_fireext { width: 30px; height: 30px; background: url(./smiles/fireext.png) }
.smile_five { width: 28px; height: 28px; background: url(./smiles/five.png) }
.smile_flame { width: 45px; height: 30px; background: url(./smiles/flame.png) }
.smile_flash { width: 28px; height: 33px; background: url(./smiles/flash.png) }
.smile_floyd { width: 45px; height: 30px; background: url(./smiles/floyd.png) }
.smile_flux { width: 30px; height: 30px; background: url(./smiles/flux.png) }
.smile_fokyeah { width: 30px; height: 30px; background: url(./smiles/fokyeah.png) }
.smile_fool { width: 45px; height: 30px; background: url(./smiles/fool.png) }
.smile_footpeka { width: 30px; height: 30px; background: url(./smiles/footpeka.png) }
.smile_fox { width: 30px; height: 30px; background: url(./smiles/fox.png) }
.smile_fp { width: 30px; height: 30px; background: url(./smiles/fp.png) }
.smile_fpd { width: 43px; height: 30px; background: url(./smiles/fpd.png) }
.smile_fpeka { width: 30px; height: 30px; background: url(./smiles/fpeka.png) }
.smile_fpl { width: 26px; height: 30px; background: url(./smiles/fpl.png) }
.smile_fpled { width: 30px; height: 30px; background: url(./smiles/fpled.png) }
.smile_frog { width: 36px; height: 30px; background: url(./smiles/frog.png) }
.smile_frog2 { width: 30px; height: 30px; background: url(./smiles/frog2.png) }
.smile_frog3 { width: 33px; height: 30px; background: url(./smiles/frog3.png) }
.smile_frogdance { width: 23px; height: 30px; background: url(./smiles/frogdance.png) }
.smile_froghap { width: 30px; height: 30px; background: url(./smiles/froghap.png) }
.smile_frogkaw { width: 30px; height: 30px; background: url(./smiles/frogkaw.png) }
.smile_frogpop { width: 30px; height: 30px; background: url(./smiles/frogpop.png) }
.smile_fry { width: 23px; height: 30px; background: url(./smiles/fry.png) }
.smile_fu { width: 28px; height: 30px; background: url(./smiles/fu.png) }
.smile_fuuu { width: 30px; height: 30px; background: url(./smiles/fuuu.png) }
.smile_fyeah { width: 30px; height: 30px; background: url(./smiles/fyeah.png) }
.smile_fyou { width: 30px; height: 30px; background: url(./smiles/fyou.png) }
.smile_gabulov { width: 30px; height: 30px; background: url(./smiles/gabulov.png) }
.smile_gachigasm { width: 30px; height: 30px; background: url(./smiles/gachigasm.png) }
.smile_gal { width: 30px; height: 30px; background: url(./smiles/gal.png) }
.smile_gao { width: 30px; height: 30px; background: url(./smiles/gao.png) }
.smile_gc { width: 30px; height: 30px; background: url(./smiles/gc.png) }
.smile_gdemamka { width: 30px; height: 30px; background: url(./smiles/gdemamka.png) }
.smile_gelfand { width: 30px; height: 30px; background: url(./smiles/gelfand.png) }
.smile_genius { width: 29px; height: 30px; background: url(./smiles/genius.png) }
.smile_geolog { width: 30px; height: 30px; background: url(./smiles/geolog.png) }
.smile_gg { width: 51px; height: 27px; background: url(./smiles/gg.png) }
.smile_gglord { width: 55px; height: 30px; background: url(./smiles/gglord.png) }
.smile_ggwp { width: 83px; height: 25px; background: url(./smiles/ggwp.png) }
.smile_ghoodie { width: 30px; height: 30px; background: url(./smiles/ghoodie.png) }
.smile_ghost { width: 30px; height: 30px; background: url(./smiles/ghost.png) }
.smile_ghostly { width: 30px; height: 30px; background: url(./smiles/ghostly.png) }
.smile_giveup { width: 30px; height: 30px; background: url(./smiles/giveup.png) }
.smile_globus { width: 30px; height: 36px; background: url(./smiles/globus.png) }
.smile_glory { width: 30px; height: 30px; background: url(./smiles/glory.png) }
.smile_gm { width: 30px; height: 30px; background: url(./smiles/gm.png) }
.smile_gml { width: 31px; height: 30px; background: url(./smiles/gml.png) }
.smile_gnazi { width: 30px; height: 30px; background: url(./smiles/gnazi.png) }
.smile_gogo { width: 30px; height: 30px; background: url(./smiles/gogo.png) }
.smile_gold { width: 32px; height: 30px; background: url(./smiles/gold.png) }
.smile_gomosp { width: 30px; height: 30px; background: url(./smiles/gomosp.png) }
.smile_grafon { width: 23px; height: 30px; background: url(./smiles/grafon.png) }
.smile_graham { width: 45px; height: 30px; background: url(./smiles/graham.png) }
.smile_gray { width: 22px; height: 30px; background: url(./smiles/gray.png) }
.smile_grcat { width: 30px; height: 30px; background: url(./smiles/grcat.png) }
.smile_gre { width: 30px; height: 30px; background: url(./smiles/gre.png) }
.smile_great { width: 38px; height: 30px; background: url(./smiles/great.png) }
.smile_grey { width: 32px; height: 30px; background: url(./smiles/grey.png) }
.smile_grind3sc2 { width: 30px; height: 30px; background: url(./smiles/grind3sc2.png) }
.smile_grrr { width: 30px; height: 30px; background: url(./smiles/grrr.png) }
.smile_grumpo { width: 30px; height: 30px; background: url(./smiles/grumpo.png) }
.smile_grumpy { width: 30px; height: 30px; background: url(./smiles/grumpy.png) }
.smile_grumpypeka { width: 30px; height: 30px; background: url(./smiles/grumpypeka.png) }
.smile_gryaz { width: 30px; height: 30px; background: url(./smiles/gryaz.png) }
.smile_grz { width: 30px; height: 30px; background: url(./smiles/grz.png) }
.smile_guf { width: 31px; height: 30px; background: url(./smiles/guf.png) }
.smile_gufmaster { width: 30px; height: 30px; background: url(./smiles/gufmaster.png) }
.smile_guitar { width: 30px; height: 30px; background: url(./smiles/guitar.png) }
.smile_gumi { width: 30px; height: 30px; background: url(./smiles/gumi.png) }
.smile_gusta { width: 30px; height: 30px; background: url(./smiles/gusta.png) }
.smile_habib { width: 30px; height: 30px; background: url(./smiles/habib.png) }
.smile_handass { width: 30px; height: 30px; background: url(./smiles/handass.png) }
.smile_happy { width: 30px; height: 30px; background: url(./smiles/happy.png) }
.smile_happy2 { width: 40px; height: 30px; background: url(./smiles/happy2.png) }
.smile_happyderp { width: 45px; height: 30px; background: url(./smiles/happyderp.png) }
.smile_happysim { width: 30px; height: 30px; background: url(./smiles/happysim.png) }
.smile_harold { width: 30px; height: 30px; background: url(./smiles/harold.png) }
.smile_haruhi { width: 45px; height: 30px; background: url(./smiles/haruhi.png) }
.smile_heaven { width: 50px; height: 30px; background: url(./smiles/heaven.png) }
.smile_hehe { width: 30px; height: 30px; background: url(./smiles/hehe.png) }
.smile_hell { width: 45px; height: 30px; background: url(./smiles/hell.png) }
.smile_hellgirl { width: 44px; height: 30px; background: url(./smiles/hellgirl.png) }
.smile_hero { width: 24px; height: 28px; background: url(./smiles/hero.png) }
.smile_heuheu { width: 30px; height: 30px; background: url(./smiles/heuheu.png) }
.smile_hg { width: 30px; height: 30px; background: url(./smiles/hg.png) }
.smile_hiforest { width: 30px; height: 30px; background: url(./smiles/hiforest.png) }
.smile_hitman { width: 30px; height: 30px; background: url(./smiles/hitman.png) }
.smile_hmhm { width: 30px; height: 30px; background: url(./smiles/hmhm.png) }
.smile_hmhmhm { width: 28px; height: 30px; background: url(./smiles/hmhmhm.png) }
.smile_hmm { width: 30px; height: 30px; background: url(./smiles/hmm.png) }
.smile_hmpeka { width: 30px; height: 30px; background: url(./smiles/hmpeka.png) }
.smile_holypeka { width: 30px; height: 30px; background: url(./smiles/holypeka.png) }
.smile_homer { width: 30px; height: 30px; background: url(./smiles/homer.png) }
.smile_hpeka { width: 30px; height: 30px; background: url(./smiles/hpeka.png) }
.smile_hs { width: 28px; height: 31px; background: url(./smiles/hs.png) }
.smile_ht { width: 30px; height: 30px; background: url(./smiles/ht.png) }
.smile_huh { width: 31px; height: 30px; background: url(./smiles/huh.png) }
.smile_hurma { width: 30px; height: 30px; background: url(./smiles/hurma.png) }
.smile_ichigo1 { width: 30px; height: 30px; background: url(./smiles/ichigo1.png) }
.smile_idk { width: 50px; height: 30px; background: url(./smiles/idk.png) }
.smile_idkderp { width: 45px; height: 30px; background: url(./smiles/idkderp.png) }
.smile_idra { width: 28px; height: 33px; background: url(./smiles/idra.png) }
.smile_igor { width: 30px; height: 30px; background: url(./smiles/igor.png) }
.smile_ilied { width: 30px; height: 30px; background: url(./smiles/ilied.png) }
.smile_ilipeka { width: 30px; height: 30px; background: url(./smiles/ilipeka.png) }
.smile_illum { width: 30px; height: 30px; background: url(./smiles/illum.png) }
.smile_imba { width: 30px; height: 33px; background: url(./smiles/imba.png) }
.smile_imbaimba { width: 69px; height: 27px; background: url(./smiles/imbaimba.png) }
.smile_imposssibruuu { width: 30px; height: 30px; background: url(./smiles/imposssibruuu.png) }
.smile_imsa { width: 45px; height: 30px; background: url(./smiles/imsa.png) }
.smile_imwy { width: 43px; height: 30px; background: url(./smiles/imwy.png) }
.smile_indycar { width: 45px; height: 30px; background: url(./smiles/indycar.png) }
.smile_infe { width: 30px; height: 26px; background: url(./smiles/infe.png) }
.smile_invalid { width: 30px; height: 30px; background: url(./smiles/invalid.png) }
.smile_iobana { width: 45px; height: 30px; background: url(./smiles/iobana.png) }
.smile_iracing { width: 30px; height: 30px; background: url(./smiles/iracing.png) }
.smile_ironman { width: 30px; height: 30px; background: url(./smiles/ironman.png) }
.smile_isaac { width: 30px; height: 30px; background: url(./smiles/isaac.png) }
.smile_isvk { width: 30px; height: 30px; background: url(./smiles/isvk.png) }
.smile_itak { width: 30px; height: 30px; background: url(./smiles/itak.png) }
.smile_its { width: 30px; height: 30px; background: url(./smiles/its.png) }
.smile_izi { width: 31px; height: 30px; background: url(./smiles/izi.png) }
.smile_izi2 { width: 60px; height: 30px; background: url(./smiles/izi2.png) }
.smile_jae { width: 28px; height: 33px; background: url(./smiles/jae.png) }
.smile_jangbi { width: 27px; height: 31px; background: url(./smiles/jangbi.png) }
.smile_japan { width: 30px; height: 30px; background: url(./smiles/japan.png) }
.smile_japl { width: 32px; height: 30px; background: url(./smiles/japl.png) }
.smile_jar { width: 30px; height: 30px; background: url(./smiles/jar.png) }
.smile_jaybob { width: 73px; height: 30px; background: url(./smiles/jaybob.png) }
.smile_jesus { width: 30px; height: 30px; background: url(./smiles/jesus.png) }
.smile_jew { width: 30px; height: 30px; background: url(./smiles/jew.png) }
.smile_jimw { width: 30px; height: 30px; background: url(./smiles/jimw.png) }
.smile_jinx2 { width: 30px; height: 30px; background: url(./smiles/jinx2.png) }
.smile_johnny5 { width: 45px; height: 30px; background: url(./smiles/johnny5.png) }
.smile_joker { width: 30px; height: 30px; background: url(./smiles/joker.png) }
.smile_jokerrr { width: 30px; height: 30px; background: url(./smiles/jokerrr.png) }
.smile_jolie { width: 26px; height: 30px; background: url(./smiles/jolie.png) }
.smile_joyful { width: 28px; height: 30px; background: url(./smiles/joyful.png) }
.smile_kacyx { width: 30px; height: 30px; background: url(./smiles/kacyx.png) }
.smile_kadolf { width: 30px; height: 30px; background: url(./smiles/kadolf.png) }
.smile_kaif { width: 30px; height: 30px; background: url(./smiles/kaif.png) }
.smile_kaleka { width: 30px; height: 30px; background: url(./smiles/kaleka.png) }
.smile_kam { width: 30px; height: 30px; background: url(./smiles/kam.png) }
.smile_kamina { width: 30px; height: 30px; background: url(./smiles/kamina.png) }
.smile_kamipeka { width: 30px; height: 30px; background: url(./smiles/kamipeka.png) }
.smile_kappaprime { width: 30px; height: 30px; background: url(./smiles/kappaprime.png) }
.smile_kas { width: 24px; height: 30px; background: url(./smiles/kas.png) }
.smile_katahuh { width: 30px; height: 30px; background: url(./smiles/katahuh.png) }
.smile_katsura { width: 30px; height: 30px; background: url(./smiles/katsura.png) }
.smile_kawai { width: 30px; height: 30px; background: url(./smiles/kawai.png) }
.smile_kawailing { width: 29px; height: 30px; background: url(./smiles/kawailing.png) }
.smile_kawapeka { width: 28px; height: 28px; background: url(./smiles/kawapeka.png) }
.smile_kcry { width: 30px; height: 30px; background: url(./smiles/kcry.png) }
.smile_kek { width: 30px; height: 30px; background: url(./smiles/kek.png) }
.smile_keltas { width: 30px; height: 30px; background: url(./smiles/keltas.png) }
.smile_keni { width: 30px; height: 30px; background: url(./smiles/keni.png) }
.smile_kfox { width: 30px; height: 30px; background: url(./smiles/kfox.png) }
.smile_kid { width: 30px; height: 30px; background: url(./smiles/kid.png) }
.smile_kid2 { width: 26px; height: 30px; background: url(./smiles/kid2.png) }
.smile_kid3 { width: 31px; height: 30px; background: url(./smiles/kid3.png) }
.smile_kim { width: 25px; height: 30px; background: url(./smiles/kim.png) }
.smile_kirgiz { width: 30px; height: 30px; background: url(./smiles/kirgiz.png) }
.smile_kiss { width: 30px; height: 30px; background: url(./smiles/kiss.png) }
.smile_kitana { width: 30px; height: 30px; background: url(./smiles/kitana.png) }
.smile_kitty { width: 30px; height: 30px; background: url(./smiles/kitty.png) }
.smile_kodzima { width: 30px; height: 30px; background: url(./smiles/kodzima.png) }
.smile_kolodka { width: 30px; height: 30px; background: url(./smiles/kolodka.png) }
.smile_konata { width: 30px; height: 30px; background: url(./smiles/konata.png) }
.smile_kor { width: 31px; height: 30px; background: url(./smiles/kor.png) }
.smile_kot { width: 30px; height: 30px; background: url(./smiles/kot.png) }
.smile_kot2 { width: 30px; height: 30px; background: url(./smiles/kot2.png) }
.smile_kot5 { width: 30px; height: 30px; background: url(./smiles/kot5.png) }
.smile_kote { width: 30px; height: 30px; background: url(./smiles/kote.png) }
.smile_krasn { width: 30px; height: 30px; background: url(./smiles/krasn.png) }
.smile_kroms1 { width: 30px; height: 30px; background: url(./smiles/kroms1.png) }
.smile_kroms2 { width: 30px; height: 30px; background: url(./smiles/kroms2.png) }
.smile_kroms3 { width: 30px; height: 30px; background: url(./smiles/kroms3.png) }
.smile_ksleep { width: 30px; height: 30px; background: url(./smiles/ksleep.png) }
.smile_kuma { width: 40px; height: 30px; background: url(./smiles/kuma.png) }
.smile_kurisu { width: 30px; height: 30px; background: url(./smiles/kurisu.png) }
.smile_kuzya { width: 30px; height: 30px; background: url(./smiles/kuzya.png) }
.smile_kylo { width: 30px; height: 30px; background: url(./smiles/kylo.png) }
.smile_kylobezmaski { width: 30px; height: 30px; background: url(./smiles/kylobezmaski.png) }
.smile_kziga { width: 45px; height: 30px; background: url(./smiles/kziga.png) }
.smile_lat { width: 30px; height: 30px; background: url(./smiles/lat.png) }
.smile_laugh { width: 30px; height: 30px; background: url(./smiles/laugh.png) }
.smile_lebedev { width: 30px; height: 30px; background: url(./smiles/lebedev.png) }
.smile_ledenec { width: 30px; height: 30px; background: url(./smiles/ledenec.png) }
.smile_legend2 { width: 30px; height: 30px; background: url(./smiles/legend2.png) }
.smile_leni { width: 30px; height: 30px; background: url(./smiles/leni.png) }
.smile_lewd { width: 30px; height: 30px; background: url(./smiles/lewd.png) }
.smile_lexx { width: 30px; height: 30px; background: url(./smiles/lexx.png) }
.smile_lfinger { width: 16px; height: 24px; background: url(./smiles/lfinger.png) }
.smile_lfx { width: 30px; height: 30px; background: url(./smiles/lfx.png) }
.smile_lgun { width: 30px; height: 24px; background: url(./smiles/lgun.png) }
.smile_lhand { width: 17px; height: 23px; background: url(./smiles/lhand.png) }
.smile_lick { width: 30px; height: 30px; background: url(./smiles/lick.png) }
.smile_ling { width: 30px; height: 30px; background: url(./smiles/ling.png) }
.smile_lis { width: 30px; height: 30px; background: url(./smiles/lis.png) }
.smile_lisa { width: 30px; height: 30px; background: url(./smiles/lisa.png) }
.smile_liz { width: 30px; height: 30px; background: url(./smiles/liz.png) }
.smile_lknife { width: 30px; height: 15px; background: url(./smiles/lknife.png) }
.smile_loh { width: 38px; height: 30px; background: url(./smiles/loh.png) }
.smile_lol { width: 25px; height: 30px; background: url(./smiles/lol.png) }
.smile_lol3 { width: 30px; height: 30px; background: url(./smiles/lol3.png) }
.smile_loltea { width: 30px; height: 30px; background: url(./smiles/loltea.png) }
.smile_long { width: 30px; height: 30px; background: url(./smiles/long.png) }
.smile_longcat { width: 30px; height: 30px; background: url(./smiles/longcat.png) }
.smile_love { width: 35px; height: 30px; background: url(./smiles/love.png) }
.smile_lrifle { width: 58px; height: 30px; background: url(./smiles/lrifle.png) }
.smile_luboiktouvideletotsmailumretcherez7dnei { width: 45px; height: 30px; background: url(./smiles/luboiktouvideletotsmailumretcherez7dnei.png) }
.smile_lucky { width: 28px; height: 30px; background: url(./smiles/lucky.png) }
.smile_lucpeka { width: 45px; height: 30px; background: url(./smiles/lucpeka.png) }
.smile_lul { width: 30px; height: 30px; background: url(./smiles/lul.png) }
.smile_mad { width: 30px; height: 30px; background: url(./smiles/mad.png) }
.smile_madao { width: 45px; height: 30px; background: url(./smiles/madao.png) }
.smile_magic { width: 30px; height: 30px; background: url(./smiles/magic.png) }
.smile_mandarin { width: 30px; height: 30px; background: url(./smiles/mandarin.png) }
.smile_manul { width: 30px; height: 30px; background: url(./smiles/manul.png) }
.smile_marine { width: 30px; height: 30px; background: url(./smiles/marine.png) }
.smile_mario { width: 33px; height: 30px; background: url(./smiles/mario.png) }
.smile_markovka { width: 30px; height: 30px; background: url(./smiles/markovka.png) }
.smile_maro { width: 43px; height: 30px; background: url(./smiles/maro.png) }
.smile_mawp { width: 45px; height: 30px; background: url(./smiles/mawp.png) }
.smile_mayuri { width: 30px; height: 30px; background: url(./smiles/mayuri.png) }
.smile_mc { width: 25px; height: 25px; background: url(./smiles/mc.png) }
.smile_mcat { width: 30px; height: 30px; background: url(./smiles/mcat.png) }
.smile_mchik { width: 30px; height: 30px; background: url(./smiles/mchik.png) }
.smile_mcoolcat { width: 30px; height: 30px; background: url(./smiles/mcoolcat.png) }
.smile_mee { width: 30px; height: 30px; background: url(./smiles/mee.png) }
.smile_megarage { width: 30px; height: 30px; background: url(./smiles/megarage.png) }
.smile_meobama { width: 30px; height: 30px; background: url(./smiles/meobama.png) }
.smile_mesad { width: 45px; height: 30px; background: url(./smiles/mesad.png) }
.smile_metrofp { width: 30px; height: 30px; background: url(./smiles/metrofp.png) }
.smile_mic { width: 30px; height: 30px; background: url(./smiles/mic.png) }
.smile_mihaface { width: 30px; height: 30px; background: url(./smiles/mihaface.png) }
.smile_miko { width: 45px; height: 30px; background: url(./smiles/miko.png) }
.smile_miku { width: 23px; height: 30px; background: url(./smiles/miku.png) }
.smile_milota { width: 30px; height: 30px; background: url(./smiles/milota.png) }
.smile_mimimi { width: 45px; height: 30px; background: url(./smiles/mimimi.png) }
.smile_mimo { width: 23px; height: 33px; background: url(./smiles/mimo.png) }
.smile_mind { width: 30px; height: 30px; background: url(./smiles/mind.png) }
.smile_mine { width: 31px; height: 30px; background: url(./smiles/mine.png) }
.smile_mkx { width: 30px; height: 30px; background: url(./smiles/mkx.png) }
.smile_mlg { width: 30px; height: 30px; background: url(./smiles/mlg.png) }
.smile_mm { width: 30px; height: 30px; background: url(./smiles/mm.png) }
.smile_mmm { width: 30px; height: 30px; background: url(./smiles/mmm.png) }
.smile_mmmm { width: 30px; height: 30px; background: url(./smiles/mmmm.png) }
.smile_mofgod { width: 30px; height: 30px; background: url(./smiles/mofgod.png) }
.smile_money { width: 30px; height: 30px; background: url(./smiles/money.png) }
.smile_moral { width: 30px; height: 30px; background: url(./smiles/moral.png) }
.smile_morty { width: 30px; height: 30px; background: url(./smiles/morty.png) }
.smile_mouse { width: 30px; height: 30px; background: url(./smiles/mouse.png) }
.smile_mraz { width: 30px; height: 30px; background: url(./smiles/mraz.png) }
.smile_msmile { width: 30px; height: 30px; background: url(./smiles/msmile.png) }
.smile_mule { width: 29px; height: 30px; background: url(./smiles/mule.png) }
.smile_mvp { width: 30px; height: 30px; background: url(./smiles/mvp.png) }
.smile_narc { width: 30px; height: 30px; background: url(./smiles/narc.png) }
.smile_naruto1 { width: 30px; height: 30px; background: url(./smiles/naruto1.png) }
.smile_naruto2 { width: 30px; height: 30px; background: url(./smiles/naruto2.png) }
.smile_nascar2017 { width: 45px; height: 30px; background: url(./smiles/nascar2017.png) }
.smile_nazipeka { width: 30px; height: 30px; background: url(./smiles/nazipeka.png) }
.smile_nc { width: 30px; height: 30px; background: url(./smiles/nc.png) }
.smile_neboley { width: 30px; height: 30px; background: url(./smiles/neboley.png) }
.smile_nekopeka { width: 30px; height: 30px; background: url(./smiles/nekopeka.png) }
.smile_nelson { width: 39px; height: 30px; background: url(./smiles/nelson.png) }
.smile_nelzya { width: 30px; height: 30px; background: url(./smiles/nelzya.png) }
.smile_nenado { width: 30px; height: 30px; background: url(./smiles/nenado.png) }
.smile_neo { width: 30px; height: 30px; background: url(./smiles/neo.png) }
.smile_nep { width: 30px; height: 30px; background: url(./smiles/nep.png) }
.smile_neponi { width: 30px; height: 30px; background: url(./smiles/neponi.png) }
.smile_nerd { width: 30px; height: 30px; background: url(./smiles/nerd.png) }
.smile_nerdwhy { width: 30px; height: 30px; background: url(./smiles/nerdwhy.png) }
.smile_nerv { width: 30px; height: 30px; background: url(./smiles/nerv.png) }
.smile_net { width: 30px; height: 30px; background: url(./smiles/net.png) }
.smile_newbalance { width: 30px; height: 30px; background: url(./smiles/newbalance.png) }
.smile_next { width: 30px; height: 30px; background: url(./smiles/next.png) }
.smile_ngjab { width: 31px; height: 30px; background: url(./smiles/ngjab.png) }
.smile_nicepeka { width: 30px; height: 30px; background: url(./smiles/nicepeka.png) }
.smile_nichobobr { width: 30px; height: 30px; background: url(./smiles/nichobobr.png) }
.smile_nichosi { width: 30px; height: 26px; background: url(./smiles/nichosi.png) }
.smile_niel { width: 30px; height: 30px; background: url(./smiles/niel.png) }
.smile_ninada { width: 30px; height: 30px; background: url(./smiles/ninada.png) }
.smile_no { width: 30px; height: 31px; background: url(./smiles/no.png) }
.smile_nofrog { width: 30px; height: 30px; background: url(./smiles/nofrog.png) }
.smile_nogod { width: 30px; height: 30px; background: url(./smiles/nogod.png) }
.smile_noname { width: 30px; height: 30px; background: url(./smiles/noname.png) }
.smile_nook { width: 30px; height: 30px; background: url(./smiles/nook.png) }
.smile_norm { width: 30px; height: 30px; background: url(./smiles/norm.png) }
.smile_norma { width: 30px; height: 30px; background: url(./smiles/norma.png) }
.smile_notbad { width: 30px; height: 30px; background: url(./smiles/notbad.png) }
.smile_notch { width: 30px; height: 30px; background: url(./smiles/notch.png) }
.smile_noya1 { width: 28px; height: 30px; background: url(./smiles/noya1.png) }
.smile_noya2 { width: 30px; height: 27px; background: url(./smiles/noya2.png) }
.smile_num1 { width: 30px; height: 30px; background: url(./smiles/num1.png) }
.smile_nupls { width: 26px; height: 30px; background: url(./smiles/nupls.png) }
.smile_nurask { width: 29px; height: 30px; background: url(./smiles/nurask.png) }
.smile_nursultan { width: 28px; height: 36px; background: url(./smiles/nursultan.png) }
.smile_nyan { width: 30px; height: 30px; background: url(./smiles/nyan.png) }
.smile_nyapoka { width: 30px; height: 30px; background: url(./smiles/nyapoka.png) }
.smile_nyasha1 { width: 30px; height: 30px; background: url(./smiles/nyasha1.png) }
.smile_obnimi { width: 30px; height: 30px; background: url(./smiles/obnimi.png) }
.smile_ocry { width: 30px; height: 30px; background: url(./smiles/ocry.png) }
.smile_ocry2 { width: 30px; height: 30px; background: url(./smiles/ocry2.png) }
.smile_ohgodwhy { width: 30px; height: 30px; background: url(./smiles/ohgodwhy.png) }
.smile_ohio { width: 45px; height: 30px; background: url(./smiles/ohio.png) }
.smile_oi { width: 30px; height: 30px; background: url(./smiles/oi.png) }
.smile_okay { width: 23px; height: 30px; background: url(./smiles/okay.png) }
.smile_oki { width: 32px; height: 30px; background: url(./smiles/oki.png) }
.smile_okok { width: 40px; height: 30px; background: url(./smiles/okok.png) }
.smile_old { width: 30px; height: 30px; background: url(./smiles/old.png) }
.smile_olsilove { width: 26px; height: 30px; background: url(./smiles/olsilove.png) }
.smile_omg { width: 30px; height: 30px; background: url(./smiles/omg.png) }
.smile_omsk { width: 30px; height: 30px; background: url(./smiles/omsk.png) }
.smile_onelove { width: 45px; height: 30px; background: url(./smiles/onelove.png) }
.smile_op { width: 49px; height: 27px; background: url(./smiles/op.png) }
.smile_opa { width: 30px; height: 30px; background: url(./smiles/opa.png) }
.smile_opeka { width: 30px; height: 30px; background: url(./smiles/opeka.png) }
.smile_orcl { width: 31px; height: 30px; background: url(./smiles/orcl.png) }
.smile_ork { width: 30px; height: 30px; background: url(./smiles/ork.png) }
.smile_orlov { width: 30px; height: 30px; background: url(./smiles/orlov.png) }
.smile_ornament { width: 30px; height: 30px; background: url(./smiles/ornament.png) }
.smile_oscar { width: 12px; height: 30px; background: url(./smiles/oscar.png) }
.smile_osu { width: 30px; height: 30px; background: url(./smiles/osu.png) }
.smile_otkazat { width: 30px; height: 30px; background: url(./smiles/otkazat.png) }
.smile_ottogood { width: 30px; height: 30px; background: url(./smiles/ottogood.png) }
.smile_oups { width: 30px; height: 30px; background: url(./smiles/oups.png) }
.smile_owlslove { width: 30px; height: 30px; background: url(./smiles/owlslove.png) }
.smile_pahom { width: 30px; height: 30px; background: url(./smiles/pahom.png) }
.smile_palpeka { width: 30px; height: 30px; background: url(./smiles/palpeka.png) }
.smile_panchin { width: 30px; height: 30px; background: url(./smiles/panchin.png) }
.smile_panda { width: 30px; height: 30px; background: url(./smiles/panda.png) }
.smile_pandanya { width: 30px; height: 30px; background: url(./smiles/pandanya.png) }
.smile_pandazlo { width: 30px; height: 30px; background: url(./smiles/pandazlo.png) }
.smile_parrot { width: 30px; height: 30px; background: url(./smiles/parrot.png) }
.smile_partyparrot { width: 30px; height: 30px; background: url(./smiles/partyparrot.png) }
.smile_passs { width: 30px; height: 30px; background: url(./smiles/passs.png) }
.smile_pastor { width: 30px; height: 30px; background: url(./smiles/pastor.png) }
.smile_pckp { width: 30px; height: 30px; background: url(./smiles/pckp.png) }
.smile_pcmaster { width: 30px; height: 30px; background: url(./smiles/pcmaster.png) }
.smile_pcorn { width: 30px; height: 30px; background: url(./smiles/pcorn.png) }
.smile_pe4al { width: 30px; height: 30px; background: url(./smiles/pe4al.png) }
.smile_pede { width: 30px; height: 30px; background: url(./smiles/pede.png) }
.smile_peka { width: 30px; height: 30px; background: url(./smiles/peka.png) }
.smile_pekacat { width: 30px; height: 30px; background: url(./smiles/pekacat.png) }
.smile_pekaking { width: 32px; height: 35px; background: url(./smiles/pekaking.png) }
.smile_pekaprison { width: 30px; height: 30px; background: url(./smiles/pekaprison.png) }
.smile_pekas { width: 40px; height: 30px; background: url(./smiles/pekas.png) }
.smile_pekasin { width: 30px; height: 30px; background: url(./smiles/pekasin.png) }
.smile_pekaver { width: 43px; height: 30px; background: url(./smiles/pekaver.png) }
.smile_pekling { width: 30px; height: 30px; background: url(./smiles/pekling.png) }
.smile_pekot3 { width: 30px; height: 30px; background: url(./smiles/pekot3.png) }
.smile_peng { width: 30px; height: 30px; background: url(./smiles/peng.png) }
.smile_penguin { width: 30px; height: 30px; background: url(./smiles/penguin.png) }
.smile_pepeisis { width: 30px; height: 30px; background: url(./smiles/pepeisis.png) }
.smile_pepeny { width: 30px; height: 30px; background: url(./smiles/pepeny.png) }
.smile_pepesm { width: 30px; height: 30px; background: url(./smiles/pepesm.png) }
.smile_peppa { width: 30px; height: 30px; background: url(./smiles/peppa.png) }
.smile_percy { width: 45px; height: 30px; background: url(./smiles/percy.png) }
.smile_personakuma { width: 45px; height: 30px; background: url(./smiles/personakuma.png) }
.smile_philo { width: 45px; height: 30px; background: url(./smiles/philo.png) }
.smile_pho { width: 30px; height: 30px; background: url(./smiles/pho.png) }
.smile_php { width: 30px; height: 30px; background: url(./smiles/php.png) }
.smile_pika { width: 52px; height: 30px; background: url(./smiles/pika.png) }
.smile_pikarun { width: 52px; height: 30px; background: url(./smiles/pikarun.png) }
.smile_piston { width: 30px; height: 30px; background: url(./smiles/piston.png) }
.smile_pixxcup { width: 30px; height: 30px; background: url(./smiles/pixxcup.png) }
.smile_plasma { width: 30px; height: 30px; background: url(./smiles/plasma.png) }
.smile_pled { width: 31px; height: 30px; background: url(./smiles/pled.png) }
.smile_ploho { width: 30px; height: 30px; background: url(./smiles/ploho.png) }
.smile_pokemon { width: 30px; height: 30px; background: url(./smiles/pokemon.png) }
.smile_poker { width: 28px; height: 30px; background: url(./smiles/poker.png) }
.smile_pollen { width: 30px; height: 30px; background: url(./smiles/pollen.png) }
.smile_pomoyka { width: 30px; height: 30px; background: url(./smiles/pomoyka.png) }
.smile_pony { width: 30px; height: 30px; background: url(./smiles/pony.png) }
.smile_pool { width: 30px; height: 30px; background: url(./smiles/pool.png) }
.smile_poolmusic { width: 30px; height: 30px; background: url(./smiles/poolmusic.png) }
.smile_popa { width: 30px; height: 30px; background: url(./smiles/popa.png) }
.smile_posos { width: 29px; height: 30px; background: url(./smiles/posos.png) }
.smile_povar { width: 30px; height: 30px; background: url(./smiles/povar.png) }
.smile_ppeka { width: 30px; height: 30px; background: url(./smiles/ppeka.png) }
.smile_pri21 { width: 30px; height: 30px; background: url(./smiles/pri21.png) }
.smile_prime { width: 33px; height: 35px; background: url(./smiles/prime.png) }
.smile_princess { width: 30px; height: 30px; background: url(./smiles/princess.png) }
.smile_privet { width: 30px; height: 30px; background: url(./smiles/privet.png) }
.smile_probe { width: 26px; height: 30px; background: url(./smiles/probe.png) }
.smile_prodolzhai { width: 45px; height: 30px; background: url(./smiles/prodolzhai.png) }
.smile_propeka { width: 30px; height: 30px; background: url(./smiles/propeka.png) }
.smile_pryatki { width: 31px; height: 28px; background: url(./smiles/pryatki.png) }
.smile_ps { width: 30px; height: 30px; background: url(./smiles/ps.png) }
.smile_psh { width: 30px; height: 30px; background: url(./smiles/psh.png) }
.smile_ptrs { width: 45px; height: 30px; background: url(./smiles/ptrs.png) }
.smile_ptrt { width: 30px; height: 30px; background: url(./smiles/ptrt.png) }
.smile_puch { width: 58px; height: 30px; background: url(./smiles/puch.png) }
.smile_purp { width: 32px; height: 30px; background: url(./smiles/purp.png) }
.smile_pushka { width: 46px; height: 30px; background: url(./smiles/pushka.png) }
.smile_pushpeka { width: 30px; height: 30px; background: url(./smiles/pushpeka.png) }
.smile_pvrt { width: 31px; height: 30px; background: url(./smiles/pvrt.png) }
.smile_pyl { width: 25px; height: 30px; background: url(./smiles/pyl.png) }
.smile_pzt { width: 30px; height: 30px; background: url(./smiles/pzt.png) }
.smile_q3 { width: 30px; height: 30px; background: url(./smiles/q3.png) }
.smile_ra { width: 31px; height: 34px; background: url(./smiles/ra.png) }
.smile_rainbowfrog { width: 35px; height: 30px; background: url(./smiles/rainbowfrog.png) }
.smile_rak { width: 30px; height: 30px; background: url(./smiles/rak.png) }
.smile_rallypeka { width: 30px; height: 30px; background: url(./smiles/rallypeka.png) }
.smile_ramthink { width: 30px; height: 30px; background: url(./smiles/ramthink.png) }
.smile_ramwat { width: 30px; height: 30px; background: url(./smiles/ramwat.png) }
.smile_randy { width: 30px; height: 30px; background: url(./smiles/randy.png) }
.smile_rat { width: 30px; height: 30px; background: url(./smiles/rat.png) }
.smile_reaper { width: 41px; height: 30px; background: url(./smiles/reaper.png) }
.smile_red { width: 32px; height: 30px; background: url(./smiles/red.png) }
.smile_reeves { width: 60px; height: 30px; background: url(./smiles/reeves.png) }
.smile_regrumpy { width: 30px; height: 30px; background: url(./smiles/regrumpy.png) }
.smile_reimu { width: 30px; height: 30px; background: url(./smiles/reimu.png) }
.smile_rfinger { width: 16px; height: 24px; background: url(./smiles/rfinger.png) }
.smile_rfive { width: 24px; height: 28px; background: url(./smiles/rfive.png) }
.smile_rgun { width: 30px; height: 24px; background: url(./smiles/rgun.png) }
.smile_rhand { width: 17px; height: 23px; background: url(./smiles/rhand.png) }
.smile_richpeka { width: 30px; height: 30px; background: url(./smiles/richpeka.png) }
.smile_rip { width: 30px; height: 30px; background: url(./smiles/rip.png) }
.smile_rknife { width: 30px; height: 15px; background: url(./smiles/rknife.png) }
.smile_rly { width: 30px; height: 30px; background: url(./smiles/rly.png) }
.smile_rmika { width: 45px; height: 30px; background: url(./smiles/rmika.png) }
.smile_rnbw { width: 25px; height: 30px; background: url(./smiles/rnbw.png) }
.smile_roach { width: 35px; height: 30px; background: url(./smiles/roach.png) }
.smile_robert { width: 30px; height: 30px; background: url(./smiles/robert.png) }
.smile_robocopguitar { width: 30px; height: 30px; background: url(./smiles/robocopguitar.png) }
.smile_rock { width: 30px; height: 30px; background: url(./smiles/rock.png) }
.smile_rock2 { width: 30px; height: 30px; background: url(./smiles/rock2.png) }
.smile_rocksimp { width: 30px; height: 30px; background: url(./smiles/rocksimp.png) }
.smile_roflll { width: 30px; height: 30px; background: url(./smiles/roflll.png) }
.smile_rogan { width: 45px; height: 30px; background: url(./smiles/rogan.png) }
.smile_roldog { width: 30px; height: 30px; background: url(./smiles/roldog.png) }
.smile_rolface { width: 30px; height: 30px; background: url(./smiles/rolface.png) }
.smile_rolhey { width: 30px; height: 30px; background: url(./smiles/rolhey.png) }
.smile_rolhy { width: 30px; height: 30px; background: url(./smiles/rolhy.png) }
.smile_rolunder { width: 30px; height: 30px; background: url(./smiles/rolunder.png) }
.smile_ronaldo { width: 24px; height: 32px; background: url(./smiles/ronaldo.png) }
.smile_ronnie { width: 30px; height: 30px; background: url(./smiles/ronnie.png) }
.smile_rrifle { width: 58px; height: 30px; background: url(./smiles/rrifle.png) }
.smile_ryuryuryu { width: 30px; height: 30px; background: url(./smiles/ryuryuryu.png) }
.smile_sad { width: 30px; height: 30px; background: url(./smiles/sad.png) }
.smile_sadbatz { width: 30px; height: 30px; background: url(./smiles/sadbatz.png) }
.smile_saitama { width: 30px; height: 30px; background: url(./smiles/saitama.png) }
.smile_sakura { width: 30px; height: 30px; background: url(./smiles/sakura.png) }
.smile_sanic { width: 30px; height: 30px; background: url(./smiles/sanic.png) }
.smile_sarcasm { width: 45px; height: 30px; background: url(./smiles/sarcasm.png) }
.smile_satan { width: 34px; height: 30px; background: url(./smiles/satan.png) }
.smile_sc { width: 30px; height: 30px; background: url(./smiles/sc.png) }
.smile_sc2 { width: 30px; height: 30px; background: url(./smiles/sc2.png) }
.smile_sc2tv1 { width: 30px; height: 30px; background: url(./smiles/sc2tv1.png) }
.smile_sceptic { width: 30px; height: 30px; background: url(./smiles/sceptic.png) }
.smile_scrt { width: 30px; height: 30px; background: url(./smiles/scrt.png) }
.smile_scv { width: 31px; height: 30px; background: url(./smiles/scv.png) }
.smile_seal { width: 30px; height: 30px; background: url(./smiles/seal.png) }
.smile_sega { width: 30px; height: 30px; background: url(./smiles/sega.png) }
.smile_sex { width: 30px; height: 30px; background: url(./smiles/sex.png) }
.smile_shara1 { width: 45px; height: 30px; background: url(./smiles/shara1.png) }
.smile_shara2 { width: 45px; height: 30px; background: url(./smiles/shara2.png) }
.smile_sharisha { width: 30px; height: 30px; background: url(./smiles/sharisha.png) }
.smile_shdevr { width: 42px; height: 30px; background: url(./smiles/shdevr.png) }
.smile_shen { width: 30px; height: 30px; background: url(./smiles/shen.png) }
.smile_shia { width: 30px; height: 30px; background: url(./smiles/shia.png) }
.smile_shimapeka { width: 30px; height: 30px; background: url(./smiles/shimapeka.png) }
.smile_shkola { width: 30px; height: 30px; background: url(./smiles/shkola.png) }
.smile_shockot { width: 38px; height: 30px; background: url(./smiles/shockot.png) }
.smile_shodan { width: 30px; height: 30px; background: url(./smiles/shodan.png) }
.smile_shon { width: 30px; height: 30px; background: url(./smiles/shon.png) }
.smile_shot { width: 30px; height: 30px; background: url(./smiles/shot.png) }
.smile_shvara { width: 30px; height: 30px; background: url(./smiles/shvara.png) }
.smile_shy { width: 30px; height: 30px; background: url(./smiles/shy.png) }
.smile_shyme { width: 30px; height: 30px; background: url(./smiles/shyme.png) }
.smile_siege { width: 43px; height: 30px; background: url(./smiles/siege.png) }
.smile_sika { width: 30px; height: 30px; background: url(./smiles/sika.png) }
.smile_silly { width: 30px; height: 30px; background: url(./smiles/silly.png) }
.smile_simon { width: 30px; height: 30px; background: url(./smiles/simon.png) }
.smile_sir { width: 30px; height: 30px; background: url(./smiles/sir.png) }
.smile_siska { width: 30px; height: 30px; background: url(./smiles/siska.png) }
.smile_skrab { width: 30px; height: 30px; background: url(./smiles/skrab.png) }
.smile_skyh { width: 30px; height: 30px; background: url(./smiles/skyh.png) }
.smile_skylewd { width: 30px; height: 30px; background: url(./smiles/skylewd.png) }
.smile_slavik { width: 25px; height: 33px; background: url(./smiles/slavik.png) }
.smile_sleepeka { width: 30px; height: 30px; background: url(./smiles/sleepeka.png) }
.smile_slice { width: 30px; height: 30px; background: url(./smiles/slice.png) }
.smile_slime { width: 30px; height: 30px; background: url(./smiles/slime.png) }
.smile_slogno { width: 30px; height: 30px; background: url(./smiles/slogno.png) }
.smile_slojna { width: 30px; height: 30px; background: url(./smiles/slojna.png) }
.smile_sloth { width: 30px; height: 30px; background: url(./smiles/sloth.png) }
.smile_slow { width: 29px; height: 32px; background: url(./smiles/slow.png) }
.smile_sluni { width: 28px; height: 30px; background: url(./smiles/sluni.png) }
.smile_sm1 { width: 30px; height: 30px; background: url(./smiles/sm1.png) }
.smile_smilesc2tv { width: 30px; height: 30px; background: url(./smiles/smilesc2tv.png) }
.smile_sml { width: 30px; height: 30px; background: url(./smiles/sml.png) }
.smile_smlpeka { width: 30px; height: 30px; background: url(./smiles/smlpeka.png) }
.smile_smorc { width: 30px; height: 30px; background: url(./smiles/smorc.png) }
.smile_sno { width: 30px; height: 30px; background: url(./smiles/sno.png) }
.smile_snowman { width: 31px; height: 30px; background: url(./smiles/snowman.png) }
.smile_so { width: 30px; height: 30px; background: url(./smiles/so.png) }
.smile_soldier { width: 30px; height: 30px; background: url(./smiles/soldier.png) }
.smile_soler { width: 30px; height: 30px; background: url(./smiles/soler.png) }
.smile_sorina { width: 30px; height: 30px; background: url(./smiles/sorina.png) }
.smile_soshame { width: 30px; height: 30px; background: url(./smiles/soshame.png) }
.smile_sosi { width: 30px; height: 30px; background: url(./smiles/sosi.png) }
.smile_sova { width: 30px; height: 30px; background: url(./smiles/sova.png) }
.smile_spectacular { width: 30px; height: 30px; background: url(./smiles/spectacular.png) }
.smile_spike { width: 30px; height: 30px; background: url(./smiles/spike.png) }
.smile_spikerain { width: 30px; height: 30px; background: url(./smiles/spikerain.png) }
.smile_spinner { width: 30px; height: 30px; background: url(./smiles/spinner.png) }
.smile_spk { width: 29px; height: 30px; background: url(./smiles/spk.png) }
.smile_spoody { width: 30px; height: 30px; background: url(./smiles/spoody.png) }
.smile_spyro { width: 30px; height: 30px; background: url(./smiles/spyro.png) }
.smile_square { width: 30px; height: 30px; background: url(./smiles/square.png) }
.smile_ssogood { width: 30px; height: 30px; background: url(./smiles/ssogood.png) }
.smile_ssw { width: 30px; height: 30px; background: url(./smiles/ssw.png) }
.smile_ssw2 { width: 30px; height: 30px; background: url(./smiles/ssw2.png) }
.smile_stalk { width: 30px; height: 30px; background: url(./smiles/stalk.png) }
.smile_stath { width: 30px; height: 30px; background: url(./smiles/stath.png) }
.smile_strelok { width: 27px; height: 30px; background: url(./smiles/strelok.png) }
.smile_superfat { width: 45px; height: 30px; background: url(./smiles/superfat.png) }
.smile_superman { width: 30px; height: 30px; background: url(./smiles/superman.png) }
.smile_support { width: 24px; height: 30px; background: url(./smiles/support.png) }
.smile_suprised { width: 30px; height: 30px; background: url(./smiles/suprised.png) }
.smile_taiga { width: 30px; height: 30px; background: url(./smiles/taiga.png) }
.smile_takane { width: 30px; height: 30px; background: url(./smiles/takane.png) }
.smile_takane1 { width: 30px; height: 30px; background: url(./smiles/takane1.png) }
.smile_takane2 { width: 30px; height: 30px; background: url(./smiles/takane2.png) }
.smile_tautau { width: 30px; height: 30px; background: url(./smiles/tautau.png) }
.smile_tea { width: 30px; height: 29px; background: url(./smiles/tea.png) }
.smile_tealemon { width: 30px; height: 30px; background: url(./smiles/tealemon.png) }
.smile_tears { width: 30px; height: 30px; background: url(./smiles/tears.png) }
.smile_ten { width: 30px; height: 30px; background: url(./smiles/ten.png) }
.smile_tesla { width: 30px; height: 30px; background: url(./smiles/tesla.png) }
.smile_tgirl { width: 26px; height: 29px; background: url(./smiles/tgirl.png) }
.smile_theweedle { width: 30px; height: 30px; background: url(./smiles/theweedle.png) }
.smile_think { width: 34px; height: 30px; background: url(./smiles/think.png) }
.smile_tigra { width: 30px; height: 30px; background: url(./smiles/tigra.png) }
.smile_tnn { width: 30px; height: 30px; background: url(./smiles/tnn.png) }
.smile_toad { width: 30px; height: 30px; background: url(./smiles/toad.png) }
.smile_toby { width: 30px; height: 30px; background: url(./smiles/toby.png) }
.smile_tom { width: 30px; height: 30px; background: url(./smiles/tom.png) }
.smile_top1sng { width: 23px; height: 30px; background: url(./smiles/top1sng.png) }
.smile_tort { width: 30px; height: 30px; background: url(./smiles/tort.png) }
.smile_totoro { width: 30px; height: 30px; background: url(./smiles/totoro.png) }
.smile_toxic { width: 45px; height: 30px; background: url(./smiles/toxic.png) }
.smile_tpeka { width: 30px; height: 30px; background: url(./smiles/tpeka.png) }
.smile_tracer { width: 30px; height: 30px; background: url(./smiles/tracer.png) }
.smile_train { width: 30px; height: 30px; background: url(./smiles/train.png) }
.smile_train1 { width: 30px; height: 30px; background: url(./smiles/train1.png) }
.smile_trap { width: 30px; height: 30px; background: url(./smiles/trap.png) }
.smile_trash { width: 30px; height: 30px; background: url(./smiles/trash.png) }
.smile_trf { width: 31px; height: 25px; background: url(./smiles/trf.png) }
.smile_trigasm { width: 30px; height: 30px; background: url(./smiles/trigasm.png) }
.smile_trixi { width: 30px; height: 30px; background: url(./smiles/trixi.png) }
.smile_trump { width: 38px; height: 30px; background: url(./smiles/trump.png) }
.smile_tuda { width: 30px; height: 30px; background: url(./smiles/tuda.png) }
.smile_tuz { width: 30px; height: 30px; background: url(./smiles/tuz.png) }
.smile_twarpeka { width: 30px; height: 30px; background: url(./smiles/twarpeka.png) }
.smile_uhti { width: 30px; height: 30px; background: url(./smiles/uhti.png) }
.smile_umaru { width: 30px; height: 30px; background: url(./smiles/umaru.png) }
.smile_ungabunga { width: 30px; height: 30px; background: url(./smiles/ungabunga.png) }
.smile_unpeka { width: 30px; height: 30px; background: url(./smiles/unpeka.png) }
.smile_unya { width: 30px; height: 30px; background: url(./smiles/unya.png) }
.smile_uprls { width: 30px; height: 30px; background: url(./smiles/uprls.png) }
.smile_uuu { width: 30px; height: 30px; background: url(./smiles/uuu.png) }
.smile_uvao { width: 30px; height: 30px; background: url(./smiles/uvao.png) }
.smile_uzbag { width: 30px; height: 30px; background: url(./smiles/uzbag.png) }
.smile_vader { width: 30px; height: 30px; background: url(./smiles/vader.png) }
.smile_vaga { width: 30px; height: 30px; background: url(./smiles/vaga.png) }
.smile_valk { width: 47px; height: 30px; background: url(./smiles/valk.png) }
.smile_vanga { width: 32px; height: 30px; background: url(./smiles/vanga.png) }
.smile_varan { width: 30px; height: 30px; background: url(./smiles/varan.png) }
.smile_vata { width: 30px; height: 30px; background: url(./smiles/vata.png) }
.smile_vboy { width: 30px; height: 30px; background: url(./smiles/vboy.png) }
.smile_vdv { width: 23px; height: 33px; background: url(./smiles/vdv.png) }
.smile_veryfun { width: 30px; height: 30px; background: url(./smiles/veryfun.png) }
.smile_vesir { width: 31px; height: 30px; background: url(./smiles/vesir.png) }
.smile_vhd { width: 45px; height: 30px; background: url(./smiles/vhd.png) }
.smile_vhd2 { width: 45px; height: 30px; background: url(./smiles/vhd2.png) }
.smile_vihodtam { width: 30px; height: 30px; background: url(./smiles/vihodtam.png) }
.smile_vivi { width: 30px; height: 30px; background: url(./smiles/vivi.png) }
.smile_vlast { width: 25px; height: 30px; background: url(./smiles/vlast.png) }
.smile_voinded { width: 30px; height: 30px; background: url(./smiles/voinded.png) }
.smile_volck { width: 31px; height: 31px; background: url(./smiles/volck.png) }
.smile_vrun { width: 23px; height: 30px; background: url(./smiles/vrun.png) }
.smile_vse { width: 63px; height: 30px; background: url(./smiles/vse.png) }
.smile_vsem { width: 30px; height: 30px; background: url(./smiles/vsem.png) }
.smile_vuvu { width: 29px; height: 27px; background: url(./smiles/vuvu.png) }
.smile_vzshuh { width: 45px; height: 30px; background: url(./smiles/vzshuh.png) }
.smile_vzuh { width: 30px; height: 30px; background: url(./smiles/vzuh.png) }
.smile_warn { width: 30px; height: 30px; background: url(./smiles/warn.png) }
.smile_wat { width: 30px; height: 30px; background: url(./smiles/wat.png) }
.smile_wb { width: 30px; height: 30px; background: url(./smiles/wb.png) }
.smile_wbm { width: 45px; height: 30px; background: url(./smiles/wbm.png) }
.smile_webcam { width: 30px; height: 30px; background: url(./smiles/webcam.png) }
.smile_welldone { width: 45px; height: 30px; background: url(./smiles/welldone.png) }
.smile_wfrog { width: 30px; height: 30px; background: url(./smiles/wfrog.png) }
.smile_wgrumpy { width: 30px; height: 30px; background: url(./smiles/wgrumpy.png) }
.smile_whathaveyoudone { width: 30px; height: 30px; background: url(./smiles/whathaveyoudone.png) }
.smile_whatif { width: 30px; height: 30px; background: url(./smiles/whatif.png) }
.smile_where { width: 30px; height: 30px; background: url(./smiles/where.png) }
.smile_white-ra { width: 27px; height: 33px; background: url(./smiles/white-ra.png) }
.smile_whut { width: 30px; height: 30px; background: url(./smiles/whut.png) }
.smile_why { width: 34px; height: 30px; background: url(./smiles/why.png) }
.smile_whyyou { width: 30px; height: 30px; background: url(./smiles/whyyou.png) }
.smile_whyyy { width: 30px; height: 30px; background: url(./smiles/whyyy.png) }
.smile_win { width: 30px; height: 30px; background: url(./smiles/win.png) }
.smile_winry { width: 20px; height: 30px; background: url(./smiles/winry.png) }
.smile_wise { width: 30px; height: 30px; background: url(./smiles/wise.png) }
.smile_wojak { width: 30px; height: 30px; background: url(./smiles/wojak.png) }
.smile_wolf { width: 30px; height: 30px; background: url(./smiles/wolf.png) }
.smile_wow { width: 31px; height: 30px; background: url(./smiles/wow.png) }
.smile_wp { width: 30px; height: 30px; background: url(./smiles/wp.png) }
.smile_wpeka { width: 30px; height: 30px; background: url(./smiles/wpeka.png) }
.smile_wpled { width: 30px; height: 30px; background: url(./smiles/wpled.png) }
.smile_wploho { width: 30px; height: 30px; background: url(./smiles/wploho.png) }
.smile_wtf { width: 38px; height: 28px; background: url(./smiles/wtf.png) }
.smile_wtfkot { width: 30px; height: 30px; background: url(./smiles/wtfkot.png) }
.smile_wuf { width: 30px; height: 30px; background: url(./smiles/wuf.png) }
.smile_x8 { width: 30px; height: 30px; background: url(./smiles/x8.png) }
.smile_xd { width: 31px; height: 30px; background: url(./smiles/xd.png) }
.smile_yao { width: 23px; height: 30px; background: url(./smiles/yao.png) }
.smile_yazhal { width: 45px; height: 30px; background: url(./smiles/yazhal.png) }
.smile_yeah { width: 30px; height: 30px; background: url(./smiles/yeah.png) }
.smile_yey { width: 30px; height: 30px; background: url(./smiles/yey.png) }
.smile_yoda { width: 30px; height: 30px; background: url(./smiles/yoda.png) }
.smile_yodashocked { width: 30px; height: 30px; background: url(./smiles/yodashocked.png) }
.smile_yoko { width: 41px; height: 30px; background: url(./smiles/yoko.png) }
.smile_yopeka { width: 28px; height: 30px; background: url(./smiles/yopeka.png) }
.smile_yuevil { width: 30px; height: 30px; background: url(./smiles/yuevil.png) }
.smile_yuno { width: 30px; height: 30px; background: url(./smiles/yuno.png) }
.smile_yuweep { width: 30px; height: 30px; background: url(./smiles/yuweep.png) }
.smile_zadr { width: 30px; height: 30px; background: url(./smiles/zadr.png) }
.smile_zaga { width: 30px; height: 30px; background: url(./smiles/zaga.png) }
.smile_zang { width: 45px; height: 30px; background: url(./smiles/zang.png) }
.smile_zayka { width: 30px; height: 30px; background: url(./smiles/zayka.png) }
.smile_zeal { width: 30px; height: 26px; background: url(./smiles/zeal.png) }
.smile_zigan { width: 30px; height: 30px; background: url(./smiles/zigan.png) }
.smile_zmbeka { width: 30px; height: 30px; background: url(./smiles/zmbeka.png) }
.smile_zoom { width: 30px; height: 30px; background: url(./smiles/zoom.png) }
.smile_zrada { width: 30px; height: 30px; background: url(./smiles/zrada.png) }
.smile_zradaa { width: 30px; height: 30px; background: url(./smiles/zradaa.png) }
.smile_zzayka { width: 30px; height: 30px; background: url(./smiles/zzayka.png) }
.smile_zzz { width: 30px; height: 30px; background: url(./smiles/zzz.png) }
